<template>
  <div class="mainPage">
    <p class="pageTitle">Create B/N code</p>
    <div class="pageWrap">
      <a-button type="primary" @click="handleCreate" size="large">
        create
      </a-button>
      <div class="bn">
        <span class="bn-value">{{BNNumValue}}</span>
        <template v-if="BNNumValue">
          <span class="primaryColor pointer mgl12" @click="handleCopy(BNNumValue)">Copy</span>
          <a-divider type="vertical" />
          <span class="primaryColor pointer" @click="handleDel">Delete</span>
        </template>
      </div>
      <div class="mgt16">
        TIPS:Each code can only be provided to one customer.
      </div>
    </div>
  </div>
</template>

<script>

export default {
  components: {},
  data  () {
    return {
      BNNumValue: ''
    }
  },
  methods: {
    async handleCreate () {
      try {
        const res = await this.$http.post('/platform/admin/ticketAdminManage/v1/generateBnCode')
        if (res.code === 0) { 
          this.BNNumValue = res.data.bnCode
        }
      } catch (error) {
        //
      }
    },
    handleDel () {
      this.BNNumValue = ''
    },
    handleCopy (text) {
      this.$copyText(text).then(() => {
        this.$message.success('copy success')
      }).catch(() => {
        this.$message.error('copy fail')
      })
    },
  }
}
</script>

<style lang='less' scoped>
.bn{
  font-size: 16px;
  display: inline-block;
  margin-left: 30px;
  vertical-align: middle;
  .bn-value{
    width: 260px;
    display: inline-block;
    height: 40px;
    line-height: 40px;
    border: 1px solid #000;
    vertical-align: middle;
    text-align: center;
    font-size: 24px;
  }
}
.mgl12{
  margin-left: 12px;
}
.mgt16 {
  margin-top: 16px;
}
</style>